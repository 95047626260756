//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: [],
    data () {
        return {
            model: [],
            modelStr: '',
            data: [{"key":"建筑","value":"建筑"},{"key":"暖通","value":"暖通"},{"key":"装饰","value":"装饰"},{"key":"给排水","value":"给排水"},{"key":"电气","value":"电气"},{"key":"消防","value":"消防"},{"key":"园林","value":"园林"},{"key":"市政","value":"市政"},{"key":"设备工程","value":"设备工程"},{"key":"弱电智能化","value":"弱电智能化"}]
        }
    },
    methods: {
        changeHandler(e) {
            console.log(e)
        }
    },
    mounted: function() {
        
    }
}
